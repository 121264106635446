import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.scss"

const Video = ({ local, youtube_id }) => {
  let video

  if (youtube_id) {
    video = (
      <iframe
        width="560"
        height="315"
        title={youtube_id}
        src={`https://www.youtube.com/embed/${youtube_id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    )
  }

  if (local) {
    video = (
      // eslint-disable-next-line
      <video width="560" height="315" controls>
        <source src={`/uploads/${local}`} type="video/mp4"></source>
      </video>
    )
  }

  return <div className={styles.video}>{video}</div>
}

Video.propTypes = {
  /**
   * Path to local video.
   */
  local: PropTypes.string,
  /**
   * ID of the youtube video.
   */
  youtube_id: PropTypes.string
}

Video.defaultProps = {}

export default Video
