import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { createPortal } from "react-dom"

import SVG from "@src/components/svg"
import Video from "@src/components/video"

import styles from "./styles.module.scss"

const toggleBodyClassName = (showing) => {
  const body = document.body.classList
  const className = "modal-is-showing"

  showing = typeof showing === "undefined" ? body.contains(className) : showing

  if (showing) {
    body.add(className)
  } else {
    body.remove(className)
  }
}

const VideoModal = ({ className, showing, video }) => {
  if (typeof window === `undefined`) {
    return <></>
  }

  useEffect(() => {
    setModal(showing)
    toggleBodyClassName(showing)
  }, [showing])

  const [isShowing, setModal] = useState(showing)
  const [childrenHaveRendered, setChildrenHaveRendered] = useState(false)

  if (isShowing && !childrenHaveRendered) {
    setChildrenHaveRendered(true)
  }

  const handleModal = (e) => {
    if (e) {
      e.preventDefault()
    }

    toggleBodyClassName(!isShowing)
    setModal(!isShowing)
  }

  document.addEventListener("keydown", function (e) {
    if (e.key !== "Escape" || !isShowing) {
      return
    }

    handleModal()
  })

  return (
    <>
      <button
        className={classNames(styles.modal_thumb, className, {})}
        onClick={(e) => handleModal(e)}
        aria-label="Open modal to watch video"
      >
        <SVG name="video-circle" />
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video height={video.height} autoPlay loop="loop" muted="muted" playsinline="playsinline">
          <source src={`/uploads/${video.thumb}`} type="video/mp4"></source>
        </video>
      </button>

      {createPortal(
        <>
          {childrenHaveRendered && (
            <div
              className={classNames(styles.modal, `animate__animated animate__fadeIn`, {
                [styles.is_showing]: isShowing
              })}
              onClick={() => handleModal()}
              role="presentation"
            >
              <div
                className={classNames(
                  styles.modal_container,
                  `animate__animated animate__fadeInDown`,
                  {}
                )}
                onClick={(e) => e.stopPropagation()}
                role="presentation"
              >
                <div className={styles.modal_header}>
                  <button
                    className={styles.close}
                    onClick={() => handleModal()}
                    aria-label="Close"
                  />
                </div>
                {isShowing ? <Video local={video.local} youtube_id={video.youtube_id} /> : null}
              </div>
            </div>
          )}
        </>,
        document.body
      )}
    </>
  )
}

VideoModal.propTypes = {
  /**
   * Additional classes on the link element.
   */
  className: PropTypes.string,
  showing: PropTypes.bool,
  video: PropTypes.object
}

VideoModal.defaultProps = {
  showing: false,
  video: {}
}

export default VideoModal
