import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import TrackVisibility from "react-on-screen"

const Animation = ({
  children,
  className,
  contentClassName,
  delay,
  fade,
  offset,
  once,
  partialVisibility,
  revealColor,
  revealFrom,
  style,
  tag
}) => {
  return (
    <TrackVisibility
      className={className}
      once={once}
      partialVisibility={partialVisibility}
      style={style}
      offset={offset}
      tag={tag}
    >
      {({ isVisible }) => (
        <div
          className={classNames(contentClassName, {
            [[`is-hidden`]]: !isVisible && !revealFrom,
            [[`animation-delay-${delay}`]]: fade && delay,
            [[`animate__animated`]]: fade,
            [[`animate__fadeIn`]]: fade === "in" && isVisible,
            [[`fadeIn-${fade}`]]: fade && isVisible,

            [[`reveal-from-${revealFrom}`]]: revealFrom,
            [[`is-showing`]]: revealFrom && isVisible,
            [[`reveal-${revealColor}`]]: revealColor,
            [[`transition-delay-${delay}`]]: revealFrom && delay
          })}
        >
          {children}
        </div>
      )}
    </TrackVisibility>
  )
}

Animation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  delay: PropTypes.number,
  fade: PropTypes.oneOf(["in", "down", "left", "right", "up"]),
  offset: PropTypes.number,
  once: PropTypes.bool,
  partialVisibility: PropTypes.bool,
  revealFrom: PropTypes.oneOf(["bottom", "left", "right", "top"]),
  revealColor: PropTypes.oneOf([
    "orange",
    "orange-white",
    "spanish-white",
    "tan",
    "terawera",
    "white"
  ]),
  style: PropTypes.object,
  tag: PropTypes.string
}

Animation.defaultProps = {
  once: true
}

export default Animation
